<template>
  <section class="components-error-container">
    <div class="topimg min-h-48 base:min-h-56">
      <img src="/img/bg/error.png" class="w-full" />
    </div>
    <div class="components-error-container-body">
      <div class="header">
        <div class="min-h-20">
          <img class="h-20 mx-auto" src="/img/pages/reward.png" />
        </div>
        <p v-t="{ path: 'components.rewards.errors.404' }" class="title" />
      </div>
      <div class="body">
        <div class="button">
          <EssentialsDefaultButton :link="{ name: 'me-rewards' }">
            {{ $t('buttons.explore') }}
          </EssentialsDefaultButton>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.components-error-container {
  @apply bg-white;
}

.topimg {
  @apply bg-theme-header;
}

.components-error-container-body {
  @apply -mt-20;
}

.header {
  @apply text-center z-10 relative;
}

.body {
  @apply bg-white text-theme-primary px-4 pb-6 relative -mt-32 pt-28;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
}

.title {
  @apply font-theme-title text-theme-primary text-4xl uppercase mt-2;
}

.button {
  @apply mt-12 flex justify-center;
}
</style>
