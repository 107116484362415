<template>
  <div class="raffle-terms">
    <p
      v-t="{ path: 'components.rewards.terms_of_participation' }"
      class="click-title"
      @click="show = true"
    />
    <Modal v-if="show" :large="true" :long="true" class="terms">
      <template #content>
        <span>
          <p class="terms-title">
            {{ $t('components.rewards.terms_of_participation') }}
          </p>
          <div class="mt-2 terms-content">
            <p class="text-2xl">1. Anwendungsbereich</p>
            <br />
            <p>
              Für die Teilnahme an einem von einem Unternehmen der Russmedia
              Gruppe*, Gutenbergstraße 1, 6858 Schwarzach (im Folgenden
              „Russmedia“ genannt), veranstalteten Gewinnspiel gelten
              nachstehende Teilnahmebedingungen.
            </p>
            <br />
            <p class="text-2xl">2. Teilnahmeberechtigte Personen</p>
            <br />
            <p>
              Zur Teilnahme berechtigt sind alle Personen, die zum Zeitpunkt der
              Teilnahme am jeweiligen Gewinnspiel das 16. Lebensjahr vollendet
              haben (im Folgenden die „TeilnehmerInnen“ genannt).<br />
              Der Kreis der TeilnehmerInnen ist auf jene einzuschränken, die das
              18. Lebensjahr zum Zeitpunkt der Teilnahme vollendet haben, wenn
              der auszulobende Gewinn nach geltenden gesetzlichen Bestimmungen
              nicht an Minderjährige abgegeben werden darf. <br />
              Russmedia behält sich das Recht vor, einen Nachweis der
              Teilnahmeberechtigung zu verlangen. Wird der Nachweis bei
              Aufforderung nicht erbracht, können TeilnehmerInnen von der
              Teilnahme an dem jeweiligen Gewinnspiel ausgeschlossen werden.
            </p>
            <br />
            <p class="text-2xl">
              3. Von der Teilnahme ausgeschlossene Personen
            </p>
            <br />
            <p>
              Von der Teilnahme jedenfalls ausgeschlossen sind alle
              Angestellten, Mitarbeiter und Berater der Russmedia, sowie
              Angestellte, Mitarbeiter und Berater etwaiger Sponsoren und/oder
              Kooperationspartner des jeweiligen Gewinnspiels. Selbiges gilt für
              Personen, die mit den Obengenannten im selben Haushalt leben.
              Russmedia ist berechtigt, TeilnehmerInnen, die gegen Strafgesetze,
              die guten Sitten oder die Teilnahmebedingungen verstoßen, mit
              sofortiger Wirkung, von der Teilnahme auszuschließen. Liegen die
              Voraussetzungen für einen Teilnahmeausschluss vor, so ist
              Russmedia außerdem berechtigt, bereits gewährte Gewinne
              zurückzufordern.
            </p>
            <br />
            <p class="text-2xl">4. Allgemeines</p>
            <br />
            <p>
              Die Teilnahme an den Gewinnspielen ist kostenlos und freiwillig,
              sowie frei von jeglichen Verpflichtungen der TeilnehmerInnen.
              TeilnehmerInnen sind pro Gewinnspiel, wenn in den jeweiligen
              Bedingungen für ein Gewinnspiel nicht anders ausgewiesen, zu einer
              Einreichung berechtigt. Weitere Einreichungen werden in die
              Ermittlung des Gewinners nicht einbezogen. <br />
              Die Gewinnchance wird nicht durch den Erwerb von Produkten
              beeinflusst. <br />
              Über Gewinnspiele kann kein Schriftverkehr geführt werden. Der
              Rechtsweg ist ausgeschlossen.
            </p>
            <br />
            <p class="text-2xl">5. Rechte Dritter</p>
            <br />
            <p>
              Die TeilnehmerInnen erklären, dass eingereichte Texte und Fotos
              nicht gegen Rechte Dritter verstoßen, dabei sind im besonderen
              Urheberrechte sowie Persönlichkeitsrechte genannt. Ist ein Dritter
              an der Erstellung eines Betrags beteiligt, so muss auch dieser mit
              der Einreichung sowie einer etwaigen späteren Veröffentlichung
              einverstanden sein. Selbiges gilt, wenn Dritte auf zur Teilnahme
              an Gewinnspielen eingesendeten Bilddaten abgebildet sind.
            </p>
            <br />
            <p class="text-2xl">6. Ablauf</p>
            <br />
            <p>
              Weitere Teilnahmebedingungen wie Typ des Gewinnspiels, Art und
              Umfang des Gewinns, der Teilnahmezeitraum, sowie etwaige
              Kooperationspartner variieren und sind der Beschreibung jeweiligen
              Aktion zu entnehmen. Im Falle, dass in der jeweiligen Aktion kein
              Teilnahmezeitraum angegeben ist, gilt als Startdatum das Datum der
              Veröffentlichung und als Enddatum der Einsendeschluss bzw. bei
              Veranstaltungen der Tag vor der Veranstaltung.<br />
              Der/die Gewinner werden nach Einsendeschluss aus den Einsendungen,
              abhängig vom jeweiligen Typ des Gewinnspiels, per Zufall oder
              Jury, unter Ausschluss der Öffentlichkeit ermittelt. Davon
              ausgenommen sind Social Media-Gewinnspiele, bei denen der Beitrag
              mit den meisten Likes als Gewinner ausgelobt werden soll. Dabei
              ist anzumerken, dass das verwendete soziale Netzwerk, als
              Plattform zur Durchführung dient und in keiner weiteren Verbindung
              zum Gewinnspiel oder Russmedia steht.<br />
              TeilnehmerInnen, die an einem Radiogewinnspiel der Russmedia
              teilnehmen, werden nach ihrer zufälligen Ermittlung als Kandidat
              von einem Moderator aus dem Studio angerufen. Wird dieser Anruf
              entgegengenommen, ist vom jeweiligen Gewinnspiel abhängig, ob die
              im Gewinnspiel gesuchte Antwort noch einmal live auf Sendung
              beantwortet werden muss oder die Auslobung direkt erfolgt. Sollte
              ein Anruf aus dem Studio erstmalig nicht entgegengenommen werden,
              so besteht weder ein Anspruch auf einen weiteren Anruf noch auf
              den Gewinn. In Folge wird ein neuer Kandidat aus dem Kreis der
              TeilnehmerInnen ermittelt. Selbiges gilt, wenn es während einer
              Liveschaltung, aus welchen Gründen auch immer, zu einer
              Unterbrechung des Telefonates kommt, bevor der Kandidat vom
              Moderator als Gewinner ausgelobt werden konnte.
            </p>
            <br />
            <p class="text-2xl">7. Entgegennahme des Gewinns</p>
            <br />
            <p>
              Die Verständigung über einen etwaigen Gewinn erfolgt innerhalb von
              14 Tagen nach Ende des Einsendeschlusses, mittels der in dem
              jeweiligen Gewinnspiel zur Teilnahme angegebenen Kontaktdaten,
              beziehungsweise über das jeweilige soziale Netzwerk, über welches
              die Aktion ausgerichtet wurde.<br />
              Mit der Teilnahme stimmen Sie zu das im Falle eines Gewinnes Ihr
              Name, sowie ein Foto der Überreichung des Gewinnes in den Medien
              der Russmedia Gruppe veröffentlicht wird,<br />
              In Folge der Verständigung über einen Gewinn hat der Gewinner
              diesen binnen 14 Tage ab Benachrichtigung bei Russmedia, an der
              oben genannten Adresse zu den allgemeinen Öffnungszeiten,
              entgegenzunehmen.<br />
              Besteht der Preis in der Teilnahme an einer bis zu 48 Stunden nach
              Einsendeschluss stattfindenden Veranstaltung, so wird der Gewinner
              unmittelbar nach dessen Ermittlung telefonisch verständigt. Sollte
              der erstmalige Anruf nicht entgegengenommen werden, so wird ein
              neuer Gewinner ermittelt. Eine Ablöse in bar, sowie der Umtausch
              eines Gewinns ist ausgeschlossen. Russmedia behält sich das Recht
              vor, den Gewinn nur an TeilnehmerInnen abzugeben, welche die
              Teilnahmebedingungen des Gewinnspiels befolgen. <br />
              Russmedia stellt den Gewinn zur Verfügung und trägt die Kosten für
              dessen Beschaffung. Entstehen dem Gewinner Kosten oder sonstige
              Aufwendungen durch dessen Entgegennahme oder Benützung, sind diese
              vom Gewinner selbst zu tragen. Weiters übernimmt Russmedia keine
              Haftung für Schäden, die dem Gewinner oder Dritten durch die
              Benützung oder im Zuge der Einlösung des Gewinnes entstehen.
            </p>
            <br />
            <p class="text-2xl">8. Datenschutz</p>
            <br />
            <p>
              Personenbezogene Daten, die der Russmedia im Zuge des laufenden
              Gewinnspieles bekannt gegeben wurden, werden nur zu dessen
              Abwicklung genützt und darüber hinaus nicht an Dritte
              weitergegeben oder zu Werbezwecken verwendet, es sei denn, es
              liegt uns eine diesbezüglich weiterreichende Einwilligung vor.
              Diese Einwilligung kann von den TeilnehmerInnen jederzeit
              widerrufen werden. <br />
              Nähere Informationen über den Umgang mit personenbezogenen Daten,
              sowie die Betroffenenrechte können der Datenschutzerklärung
              entnommen werden.<br />
              Gegebenenfalls werden im Rahmen laufenden Aktion Einsendebeiträge
              durch Russmedia oder Kooperationspartner zur Gewinnspiel-Promotion
              auf deren jeweiliger Webseite anonymisiert veröffentlicht.
            </p>
            <br />
            <p class="text-2xl">
              9. Änderungen der Teilnahmebedingungen und Beendigung des
              Gewinnspieles
            </p>
            <br />
            <p>
              Russmedia behält sich das Recht vor, diese Teilnahmebedingungen
              jederzeit zu ändern. Eine solche Änderung ist umgehend bekannt zu
              geben. Die Bekanntgabe kann auch durch Veröffentlichung auf der
              Webseite von Russmedia erfolgen.<br />
              Weiters ist Russmedia berechtigt, die Aktion jederzeit aus
              wichtigem Grund zu beenden oder zu unterbrechen. Als solche
              wichtigen Gründe sind im Besonderen jene zu verstehen, die den
              planmäßigen Ablauf verhindern oder behindern würden.
            </p>
            <br />
            <p class="text-2xl">10. zusätzliche Bestimmungen</p>
            <br />
            <p>
              Russmedia Digital GmbH behält sich vor, Bilder jeglicher Art, die
              auf VOL.AT hochgeladen werden, zu veröffentlichen. Dies betrifft
              vor allem Bilder, die für Foto-Votings hochgeladen oder
              eingesendet werden. Diese Bilder werden im Rahmen des Wettbewerbs
              zur Bewertung auf VOL.AT veröffentlicht. Sollten einzelne
              Bestimmungen dieser Teilnahmebedingungen ungültig oder
              undurchführbar sein oder ihre Wirksamkeit verlieren, bleibt die
              Wirksamkeit der übrigen Bestimmungen davon unberührt. An Stelle
              der ungültigen oder unwirksamen Bestimmungen treten jene
              gesetzlichen Regelungen, die deren wirtschaftlichen Zweck und
              Zielsetzung im Ausdruck am Nächsten kommen. Entsprechendes gilt
              für den Fall des Vorliegens einer Regelungslücke in diesen
              Teilnahmebedingungen. Es gilt österreichisches materielles Recht
              unter Ausschluss der Kollisionsnormen des internationalen
              Privatrechts. Ausschließlicher Gerichtsstand ist Schwarzach,
              Vorarlberg.<br />
            </p>
            <br />
            <p class="text-2xl">11. Haftungshinweis</p>
            <br />
            <p>
              Apple Inc. ist weder Sponsor noch in einer anderen Form an unseren
              Gewinnspielen und Wettbewerben beteiligt.<br />
              <br />
              *(= Russmedia GmbH, NEUE Zeitungs GmbH, Russmedia IT GmbH,
              Russmedia Verlag GmbH, Antenne Vorarlberg GmbH, Russmedia Digital
              GmbH) <br />
              <br />
              Stand: 20.05.2019<br /><br />
            </p>
          </div>
          <EssentialsDefaultButtonEvent @click="show = false">
            {{ $t('buttons.ok') }}
          </EssentialsDefaultButtonEvent>
        </span>
      </template>
    </Modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.raffle-terms {
  @apply w-2/3 mx-1 cursor-pointer;
}

.click-title {
  @apply py-1 text-theme-primary text-center border border-theme-primary border-solid rounded-full;
}

.terms-title {
  @apply text-2xl text-theme-primary font-theme-title;
}

.terms-content {
  @apply h-64 mb-4 overflow-y-scroll;
}
</style>
