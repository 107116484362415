<template>
  <div class="reward-page-panel">
    <div class="loader">
      <RewardsOrderPanelLoader v-if="loading" />
    </div>
    <div class="content">
      <slot />
    </div>
  </div>
</template>
<script>

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.reward-page-panel {
    @apply shadow-detail bg-white overflow-hidden rounded-theme-large;
}

.loader {
    min-height: 4px;
}

.content {
    @apply p-8;
}
</style>
