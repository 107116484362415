<template>
  <div>
    <div v-if="reward.payment_type === 'raffle'">
      <p
        v-t="{ path: 'components.rewards.modal.title_success_raffle' }"
        class="title"
      />
      <p
        v-t="{
          path: 'components.rewards.modal.text_success_raffle',
        }"
        class="text"
      />
      <div class="success-btn">
        <EssentialsDefaultButtonEvent @click="$emit('resetAndBack')">{{
          $t('components.rewards.explore_more')
        }}</EssentialsDefaultButtonEvent>
      </div>
    </div>
    <div v-else-if="reward.delivery_type === 'coupon'">
      <p
        v-t="{ path: 'components.rewards.modal.title_success_coupon' }"
        class="title"
      />
      <p
        v-t="{
          path: 'components.rewards.modal.text_success_coupon',
        }"
        class="text"
      />
      <div class="success-btn-multiple">
        <EssentialsDefaultButtonEvent class="w-full" @click="$emit('resetAndNavigate', route)">{{
          $t('components.rewards.modal.to_reward')
        }}</EssentialsDefaultButtonEvent>
        <EssentialsDefaultButtonEvent class="w-full mt-2" @click="$emit('resetAndBack')">{{
          $t('components.rewards.explore_more')
        }}</EssentialsDefaultButtonEvent>
      </div>
    </div>
    <div v-else>
      <p
        v-t="{ path: 'components.rewards.modal.title_success' }"
        class="title"
      />
      <p
        v-t="{
          path: 'components.rewards.modal.text_success_digital',
          args: { price: reward.price.toLocaleString('de-DE') },
        }"
        class="text"
      />
      <div class="success-btn-multiple">
        <EssentialsDefaultButtonEvent class="w-full" @click="$emit('resetAndBack')">{{
          $t('components.rewards.explore_more')
        }}</EssentialsDefaultButtonEvent>
        <EssentialsDefaultButtonEvent
          class="w-full mt-2"
          @click="$emit('resetAndNavigate', rewards)"
          >{{ $t('components.rewards.modal.to_my_reward') }}</EssentialsDefaultButtonEvent
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapStores } from 'pinia';

export default {
  props: {
    reward: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapStores(useOrderStore),
    rewardItemId() {
      return this.orderStore.rewardItemId;
    },
    route() {
      return {
        name: 'me-rewards-detail-coupon-id',
        params: { id: this.rewardItemId },
      };
    },
    rewards() {
      return {
        name: 'me-profile-rewards',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  @apply text-theme-secondary font-theme-title text-2xl uppercase pb-2;
}

.text {
  @apply text-theme-primary font-theme-text leading-tight pb-4;
}

.success-btn {
  @apply flex flex-row justify-center mt-2;
}

.success-btn-multiple {
  @apply mt-2 my-4;
}
</style>
