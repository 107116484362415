<template>
  <div>
    <p
      v-t="{ path: 'components.rewards.modal.title_almost_finished' }"
      class="title"
    />
    <p
      v-t="{
        path: 'components.rewards.modal.confirm_text',
        args: { price: reward.price.toLocaleString('de-DE') },
      }"
      class="text"
    />
    <p
      v-t="{ path: 'components.rewards.modal.address_missing_text' }"
      class="text"
    />
    <RewardsOrderProfileForm
      :delivery-type="reward.delivery_type"
      @updated="formUpdated"
    />
    <div v-if="!privacy" class="my-4">
      <EssentialsCheckBox id="dsgvo" v-model="privacy">
        <p v-t="{ path: 'components.rewards.dsgvo' }" class="checkbox-text" />
      </EssentialsCheckBox>
      <p
        v-t="{ path: 'components.rewards.dsgvo_fulltext' }"
        class="pl-10 text-sm text-theme-primary"
      />
    </div>
    <div class="button-group">
      <EssentialsDefaultButtonEvent
        :disabled="loading"
        :highlighted="true"
        class="w-full mr-2"
        @click="$emit('retryClose')"
      >{{ $t('buttons.abort') }}</EssentialsDefaultButtonEvent>
      <EssentialsDefaultButtonEvent
        :disabled="!canOrder || loading"
        class="w-full ml-2"
        @click="confirm()"
      >{{ $t('components.rewards.redeem') }}</EssentialsDefaultButtonEvent>
    </div>
  </div>
</template>
<script>
import { mapStores } from 'pinia';

export default {
  props: {
    reward: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      profileData: {},
      formFilled: false,
      formValid: false,
      dsgvo_checked: false,
    };
  },
  computed: {
    ...mapStores(useOrderStore, useMeStore),
    canOrder() {
      return this.formValid && this.formFilled;
    },
    loading() {
      return this.orderStore.state === 'loading';
    },
    privacy: {
      get() {
        return this.meStore.privacy;
      },
      set(newVal) {
        this.dsgvo_checked = newVal;
      },
    },
  },
  mounted() {
    this.dsgvo_checked = this.privacy;
  },
  methods: {
    formUpdated(value) {
      this.profileData = value.formData;
      this.formFilled = value.filled;
      this.formValid = value.valid;
    },
    confirm() {
      this.orderStore.setOrderDetails(this.profileData);
      this.meStore.updatePersonalData({
        privacy: Boolean(this.dsgvo_checked).toString(),
        ...this.profileData,
      });
      this.orderStore.order(this.reward.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  @apply text-theme-secondary font-theme-title text-2xl uppercase pb-2;
}

.text {
  @apply text-theme-primary font-theme-text leading-tight pb-4;
}

.button-group {
  @apply flex flex-row justify-center mt-2;
}

.checkbox-text {
  line-height: 16px;
  @apply text-theme-primary pl-1;
}

@media (min-width: 413px) {
  .checkbox-text {
    line-height: 32px;
  }
}
</style>
