<template>
  <div>
    <p
      v-t="{ path: 'components.rewards.modal.title_almost_finished' }"
      class="title"
    />
    <p
      v-t="{
        path: 'components.rewards.modal.confirm_text',
        args: { price: reward.price.toLocaleString('de-DE') },
      }"
      class="text"
    />
    <p
      v-t="{
        path: 'components.rewards.modal.digital_email',
        args: { email: storedEmail },
      }"
      class="text"
    />
    <div class="button-group">
      <EssentialsDefaultButtonEvent
        :disabled="loading"
        :highlighted="true"
        class="w-full mr-2"
        @click="$emit('retryClose')"
      >{{ $t('buttons.abort') }}</EssentialsDefaultButtonEvent>
      <EssentialsDefaultButtonEvent
        :disabled="loading"
        class="w-full ml-2"
        @click="confirm()"
      >{{ $t('components.rewards.redeem') }}</EssentialsDefaultButtonEvent>
    </div>
  </div>
</template>
<script>
import { mapStores } from 'pinia';

export default {
  props: {
    reward: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapStores(useOrderStore, useMeStore),
    storedEmail() {
      return this.meStore.personalData('email');
    },
  },
  methods: {
    confirm() {
      this.orderStore.setOrderDetails({ email: this.storedEmail });
      this.orderStore.order(this.reward.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  @apply text-theme-secondary font-theme-title text-2xl uppercase pb-2;
}

.text {
  @apply text-theme-primary font-theme-text leading-tight pb-4;
}

.button-group {
  @apply flex flex-row justify-center mt-2;
}
</style>
