<template>
  <div>
    <VueSlider
      ref="slider"
      v-model="sliderValue"
      :min="min"
      :max="max"
      :dot-size="40"
      :bg-style="{ backgroundColor: '#4A4A4A' }"
      :process-style="{ backgroundColor: '#4A4A4A' }"
      :slider-style="{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }"
      tooltip="none"
      class="my-6"
    >
      <template #dot>
        <div class="dot">
          <p v-if="showDotText" class="dot-text">
            {{ sliderValue }}
          </p>
        </div>
      </template>
    </VueSlider>
  </div>
</template>
<script>
import 'vue-slider-component/dist-css/vue-slider-component.css';
import 'vue-slider-component/theme/default.css';
import VueSlider from 'vue-slider-component/dist-css/vue-slider-component.umd.min.js';

export default {
  components: {
    VueSlider,
  },
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 10,
    },
    showDotText: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    sliderValue: {
      get() {
        return this.modelValue;
      },
      set(newVal) {
        this.$emit('update:modelValue', Number(newVal));
      },
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.dot {
  @apply h-12 w-12 rounded-full justify-center items-center bg-white shadow-box-lg flex;
  margin-top: -5px;
}

.dot-text {
  @apply text-theme-primary text-2xl;
}
</style>
