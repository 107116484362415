<template>
  <div class="terms-raffle">
    <p
      v-t="{ path: 'components.rewards.terms_of_participation' }"
      class="click-title"
      @click="show = true"
    />
    <Modal v-if="show" :large="true" :long="true" class="terms">
      <template #content>
        <span>
          <p class="terms-title">
            {{ $t('components.rewards.terms_of_participation') }}
          </p>
          <div class="mt-2 terms-content">
            <p v-t="{ path: 'components.rewards.terms_reward' }" />
          </div>
          <EssentialsDefaultButtonEvent @click="show = false">
            {{ $t('buttons.ok') }}
          </EssentialsDefaultButtonEvent>
        </span>
      </template>
    </Modal>
  </div>
</template>
<script>

export default {
  data() {
    return {
      show: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.terms-raffle {
  @apply w-2/3 mx-1 cursor-pointer;
}

.click-title {
  @apply py-1 text-theme-primary text-center border border-theme-primary border-solid rounded-full;
}

.terms-title {
  @apply text-2xl text-theme-primary font-theme-title;
}

.terms-content {
  @apply h-56 mb-6 text-theme-primary;
}
</style>
