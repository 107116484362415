<template>
  <div>
    <p v-t="{ path: 'components.rewards.modal.title_error' }" class="title" />
    <p v-t="{ path: `components.${errorPath}` }" class="text" />
    <div class="retry-btn">
      <EssentialsDefaultButtonEvent @click="$emit('retryClose')">Okay</EssentialsDefaultButtonEvent>
    </div>
  </div>
</template>
<script>
import { mapStores } from 'pinia';

export default {
  computed: {
    ...mapStores(useOrderStore),
    errorPath() {
      return this.orderStore.errorpath;
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  @apply text-theme-secondary font-theme-title text-2xl uppercase pb-2;
}

.text {
  @apply text-theme-primary font-theme-text leading-tight pb-4;
}

.retry-btn {
  @apply flex flex-row justify-center mt-2;
}
</style>
