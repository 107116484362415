<template>
  <div>
    <RewardsOrderPaymentProcess v-if="reward.payment_type !== 'fund'" :reward="reward" @reset="$emit('reset')" />
    <LazyRewardsFundOrderPaymentProcess v-else :reward="reward" />
  </div>
</template>

<script>
export default {
  props: {
    reward: {
      type: Object,
      required: true
    }
  }
}
</script>
