<template>
  <div>
    <p v-t="{ path: 'components.rewards.raffleform_title' }" class="title" />
    <RewardsOrderProfileForm :delivery-type="reward.delivery_type" @updated="formUpdated">
      <template #after-form>
        <div v-if="reward.raffle_questions" class="mt-4">
          <RewardsOrderRaffleQuestion
            v-for="question in reward.raffle_questions"
            :key="question.name"
            :question="question"
            @updated="questionUpdated"
          />
        </div>
      </template>
    </RewardsOrderProfileForm>
    <div class="my-4">
      <EssentialsCheckBox id="terms" v-model="terms">
        <p
          v-t="{ path: 'components.rewards.raffle_terms' }"
          class="checkbox-text"
        />
      </EssentialsCheckBox>
      <EssentialsCheckBox v-if="!privacy" id="dsgvo" v-model="privacy">
        <p v-t="{ path: 'components.rewards.dsgvo' }" class="checkbox-text" />
      </EssentialsCheckBox>
      <p
        v-if="!privacy"
        v-t="{ path: 'components.rewards.dsgvo_fulltext' }"
        class="pl-10 text-sm text-theme-primary"
      />
    </div>
    <div class="button-group">
      <EssentialsDefaultButtonEvent
        :disabled="loading"
        :highlighted="true"
        class="w-full mr-2"
        @click="$emit('retryClose')"
      >{{ $t('buttons.abort') }}</EssentialsDefaultButtonEvent>
      <EssentialsDefaultButtonEvent
        :disabled="!canOrder || loading"
        class="w-full ml-2"
        @click="confirm()"
      >{{ $t('components.rewards.redeem') }}</EssentialsDefaultButtonEvent>
    </div>
  </div>
</template>
<script>
import { mapStores } from 'pinia';

export default {
  props: {
    reward: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      profileData: {},
      questionAnswers: {},
      formFilled: false,
      formValid: false,
      terms: false,
      dsgvo_checked: false,
    };
  },
  computed: {
    ...mapStores(useOrderStore, useMeStore),
    canOrder() {
      return (
        this.formValid && this.formFilled && this.terms && this.questionsFilled
      );
    },
    loading() {
      return this.orderStore.state === 'loading';
    },
    privacy: {
      get() {
        return this.meStore.privacy;
      },
      set(newVal) {
        this.dsgvo_checked = newVal;
      },
    },
    questionsFilled() {
      if (this.reward.raffle_questions) {
        return (
          this.reward.raffle_questions.length ===
          Object.keys(this.questionAnswers).length
        );
      }
      return true;
    },
  },
  mounted() {
    this.dsgvo_checked = this.privacy;
  },
  methods: {
    formUpdated(value) {
      this.profileData = value.formData;
      this.formFilled = value.filled;
      this.formValid = value.valid;
    },
    confirm() {
      this.orderStore.setOrderDetails(this.profileData);
      this.orderStore.SET_RAFFLEQUESTIONS(this.questionAnswers);
      this.meStore.updatePersonalData({
        privacy: Boolean(this.dsgvo_checked).toString(),
        ...this.profileData,
      });
      this.orderStore.participate(this.reward.id);
    },
    questionUpdated(value) {
      if (value.filled) {
        this.questionAnswers[value.name] = value.answer;
      } else if (this.questionAnswers[value.name] !== undefined) {
        delete this.questionAnswers[value.name];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  @apply text-theme-secondary font-theme-title text-2xl uppercase pb-2;
}

.text {
  @apply text-theme-primary font-theme-text leading-tight pb-4;
}

.button-group {
  @apply flex flex-row justify-center mt-4;
}

#terms, #dsgvo {
  height: 32px;
  margin-bottom: 0.5rem;
}

.checkbox-text {
  line-height: 16px;
  @apply text-theme-primary pl-1;
}

@media (min-width: 374px) {
  #terms .checkbox-text {
    line-height: 32px;
  }
}

@media (min-width: 413px) {
  #dsgvo .checkbox-text {
    line-height: 32px;
  }
}
</style>
