<template>
  <div class="panel-loader"></div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.panel-loader {
    @apply bg-gradient-to-r from-transparent via-theme-secondary to-transparent;
    height: 4px;
    background-size: 200% auto;
    background-position: 0 0;
    animation: gradient 1.2s linear infinite;
}

@keyframes gradient {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -200% 0;
  }
}
</style>
