import { defineStore } from 'pinia'
import { useGTM, useTracking } from '~/composables/useTracking'
import { useMainStore } from '~/store/main'
import { useMeStore } from '~/store/me'
import { useRewardsStore } from '~/store/rewards'

type OrderState = {
  state: string
  errorpath: string
  raffleTickets: number
  fundPrice: number
  orderDetails: any
  raffleQuestions: any
  rewardItemId: number
}
export const useOrderStore = defineStore('order', {
  state: (): OrderState => ({
    state: 'loading',
    errorpath: 'rewards.errors.empty',
    raffleTickets: 0,
    fundPrice: 0,
    orderDetails: {},
    raffleQuestions: {},
    rewardItemId: 0
  }),

  actions: {
    SET_STATE(v: string) {
      this.state = v
    },
    SET_ERRORPATH(v: string) {
      this.errorpath = v
    },
    SET_RAFFLE_TICKETS(v: number) {
      this.raffleTickets = v
    },
    SET_ORDERDETAILS(v: any) {
      this.orderDetails = v
    },
    SET_RAFFLEQUESTIONS(v: any) {
      this.raffleQuestions = v
    },
    SET_FUND_PRICE(v: number) {
      this.fundPrice = v
    },
    SET_REWARD_ITEM_ID(value: number) {
      this.rewardItemId = value
    },
    async reservate(id: number) {
      try {
        const data = await useApi().$post(`/me/rewards/reserve/${id}`)

        if (data.status === 'ok') {
          this.checkSpecificRules()
          // useTracking('checkout/start')
        } else {
          this.SET_STATE('error')
        }
      } catch (e: any) {
        if (e.statusCode === 422) {
          this.SET_ERRORPATH(e.data.lang_path)
        }
        if (e.data.status === 'already_reserved_item') {
          this.checkSpecificRules() // continue if an item is already reserved for this collector
        } else {
          this.SET_STATE('error')
        }
      }
    },

    checkSpecificRules() {
      const meStore = useMeStore()
      if (meStore.profileAvailable || process.env.environment === 'local') {
        this.SET_STATE('confirm')
      } else {
        this.SET_ERRORPATH('rewards.errors.no_hash_token')
        this.SET_STATE('error')
      }
    },

    async order(id: number) {
      const reward = useRewardsStore()

      this.SET_STATE('loading')
      try {
        const data = await useApi().$post(`/me/rewards/order/${id}`, {
          details: this.orderDetails
        })

        if (data.status === 'ok') {
          this.SET_STATE('success')
          useTracking('checkout/confirm')
          try {
            const mainStore = useMainStore()
            useGTM().pushEvent({
              event: 'genericEvent',
              eventCategory: mainStore.project,
              eventAction: 'Checkout-Praemie',
              eventLabel: reward.getSingleReward(id).title
            })
          } catch (e: any) {
            console.log(e)
          }

          this.SET_REWARD_ITEM_ID(data.reward_item_id)
        } else {
          this.SET_STATE('error')
        }
      } catch (e: any) {
        if (e.statusCode === 422) {
          this.SET_ERRORPATH(e.data.lang_path)
        }
        this.SET_STATE('error')
      }
    },

    async orderFund(id: any) {
      const reward = useRewardsStore()
      this.SET_STATE('loading')
      try {
        const data = await useApi().$post(`/me/rewards/fund/${id}`, {
          price: this.fundPrice
        })

        reward.FETCH_REWARD_SINGLE_SUCCESS(data.reward)
        this.SET_STATE('success')

        useTracking('checkout/confirm')
        const mainStore = useMainStore()
        useGTM().pushEvent({
          event: 'genericEvent',
          eventCategory: mainStore.project,
          eventAction: 'Checkout-Spende',
          eventLabel: reward.getSingleReward(id).title
        })
      } catch (e: any) {
        useBugsnag().notify(e)
        if (e.statusCode === 422) {
          this.SET_ERRORPATH(e.data.lang_path)
        }
        this.SET_STATE('error')
      }
    },

    async participate(id: any) {
      const reward = useRewardsStore()
      this.SET_STATE('loading')
      try {
        const data = await useApi().$post(
          `/me/rewards/participate/${id}`,
          {
            entries: this.raffleTickets,
            questions: this.raffleQuestions,
            personal_data: this.orderDetails
          }
        )
        if ('amount' in data) {
          const mainStore = useMainStore()
          useGTM().pushEvent({
            event: 'genericEvent',
            eventCategory: mainStore.project,
            eventAction: 'Checkout-Gewinnspiel',
            eventLabel: reward.getSingleReward(id).title
          })
          this.SET_STATE('success')
        } else {
          this.SET_STATE('error')
        }
      } catch (e: any) {
        useBugsnag().notify(e)
        if (e.statusCode === 422) {
          this.SET_ERRORPATH(e.data.lang_path)
        }
        this.SET_STATE('error')
      }
    },

    async releaseReservation(id: number) {
      await useApi().$post(`/me/rewards/release/${id}`)
    },

    reset() {
      this.SET_STATE('loading')
      this.SET_ERRORPATH('rewards.errors.empty')
      this.SET_ORDERDETAILS({})
      this.SET_REWARD_ITEM_ID(0)
    },

    resetAndReload() {
      this.reset()
      const meStore = useMeStore()
      meStore.fetchCollector()
    },

    openExternalLink(externalLink: string) {
      const mainStore = useMainStore()
      this.reset()
      mainStore.openExternalLink(externalLink)
    },

    setOrderDetails(orderDetails: any) {
      useTracking('checkout/address')
      this.SET_ORDERDETAILS(orderDetails)
    },

    setRaffleTickets(tickets: number) {
      useTracking('raffle/start')
      this.SET_RAFFLE_TICKETS(tickets)
    },

    setFundPrice(price: number) {
      this.SET_FUND_PRICE(price)
    }
  }
})
