<template>
  <div>
    <p v-t="{ path: 'components.rewards.modal.title_loading' }" class="title" />
    <p v-t="{ path: 'components.rewards.modal.reservate_text' }" class="text" />
  </div>
</template>

<style lang="scss" scoped>
.title {
  @apply text-theme-secondary font-theme-title text-2xl uppercase pb-2;
}

.text {
  @apply text-theme-primary font-theme-text leading-tight pb-4;
}
</style>
