<template>
  <div class="info-box">
    <p
      v-if="!sufficientFunds && !collector_anonym && !isFund"
      v-t="{
        path: 'components.rewards.insufficient_funds',
        args: { currency: meStore.currencyNameTranslated($t) },
      }"
      class="text"
    />
    <p
      v-if="isFund && !sufficientFunds"
      v-t="{ path: 'components.rewards.insufficient_funds_fund_ticket' }"
      class="text"
    />
    <p
      v-if="!available && !reward.items_left"
      v-t="{ path: 'components.rewards.errors.soldout' }"
      class="text"
    />
    <p
      v-else-if="!available && redeemlimitreached"
      v-t="{ path: 'components.rewards.errors.redeemable_count' }"
      class="text"
    />
    <p
      v-if="!available && redeemTime === 'from_date'"
      v-t="{ path: 'components.rewards.errors.redeem_from' }"
      class="text"
    />
    <p
      v-if="!available && redeemTime === 'to_date' && !isAdventCategory"
      v-t="{ path: 'components.rewards.errors.redeem_to' }"
      class="text"
    />
    <p
      v-if="!available && redeemTime === 'to_date' && isAdventCategory"
      v-t="{ path: 'components.rewards.errors.redeem_to_advent' }"
      class="text"
    />
    <p
      v-if="collector_anonym"
      v-t="{ path: 'components.rewards.anonym_collector' }"
      class="text"
    />
    <p
      v-if="notForYou && reward.payment_type === 'raffle'"
      v-t="{ path: 'components.rewards.subscribers_only' }"
      class="text"
    />

    <p
      v-if="notForYou && reward.payment_type === 'buy'"
      v-t="{ path: 'components.rewards.subscribers_only_buy' }"
      class="text"
    />
  </div>
</template>
<script>
import { mapStores } from 'pinia';

export default {
  props: {
    reward: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapStores(useMeStore, useMainStore),
    isFund() {
      return this.reward.payment_type === 'fund';
    },
    available() {
      return this.reward.available;
    },
    redeemlimitreached() {
      if (this.reward.redeemable_count > 0) {
        return this.reward.purchased_count >= this.reward.redeemable_count;
      }
      return false;
    },
    sufficientFunds() {
      return this.meStore.points >= this.reward.price;
    },
    collector_anonym() {
      return this.meStore.anonymous;
    },
    redeemTime() {
      return this.reward.redeem_possible_by_time;
    },
    isAdventCategory() {
      return this.reward.categories
        .map((c) => c.id)
        .includes(Number(this.mainStore.special_category));
    },
    isPremiumUser() {
      return this.reward.subs_redeemable;
    },
    exclusiveDeal() {
      return this.reward.subs_only;
    },
    notForYou() {
      return this.exclusiveDeal && !this.isPremiumUser;
    },
  },
};
</script>

<style lang="scss" scoped>
.info-box {
  @apply px-4 pb-4;
}

.text {
  @apply text-theme-secondary font-black mt-4 text-center;
}
</style>
