<template>
  <div>
    <p
      v-t="{ path: 'components.rewards.modal.title_raffle_tickets' }"
      class="title"
    />
    <p
      v-if="!allInOnly"
      v-t="{ path: 'components.rewards.modal.text_raffle_tickets' }"
      class="text"
    />
    <span
      v-if="!allInOnly"
      v-t="{ path: 'components.rewards.modal.hint_raffle_tickets' }"
      class="hint"
    />

    <div v-if="allInOnly" class="pb-4">
      <span
        v-t="{ path: 'components.rewards.modal.hint_raffle_all_in_only' }"
        class="pb-4 hint"
      />
    </div>

    <EssentialsRangeSlider
      v-if="!allInOnly"
      v-model="tickets"
      :min="0"
      :max="maxTickets"
    />

    <div v-if="!allInOnly" class="flex items-center justify-center mb-6">
      <p
        v-t="{ path: 'components.rewards.buttons.allin' }"
        class="allin-button"
        @click="allIn"
      />
    </div>

    <div class="details">
      <p
        v-t="{ path: 'components.rewards.modal.text_raffle_tickets_cost' }"
        class="title"
      />
      <div class="price">
        <p>{{ cost.toLocaleString('de-DE') }}</p>
        <img :src="currencyIcon" />
      </div>
    </div>
    <div class="details">
      <p
        v-t="{
          path: 'components.rewards.modal.text_raffle_tickets_participations',
        }"
        class="title"
      />
      <div class="price">
        <p>{{ tickets.toLocaleString('de-DE') }}</p>
      </div>
    </div>
    <div class="info-space">
      <p
        v-if="!pointsAvailable"
        v-t="{ path: 'components.rewards.insufficient_funds_raffle_ticket' }"
        class="error"
      />
    </div>
    <div class="button-group">
      <EssentialsDefaultButtonEvent
        :highlighted="true"
        class="w-full mr-2"
        @click="$emit('retryClose')"
      >
        {{ $t('buttons.abort') }}
      </EssentialsDefaultButtonEvent>
      <EssentialsDefaultButtonEvent
        :disabled="tickets === 0 || !pointsAvailable"
        class="w-full ml-2"
        @click="redeem()"
      >
        {{ $t('components.rewards.buttons.continue') }}
      </EssentialsDefaultButtonEvent>
    </div>
  </div>
</template>

<script>
import { mapStores } from 'pinia'

export default {
  props: {
    reward: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tickets: 0,
      maxSelectableTickets: 100,
      allInPressed: false,
    }
  },
  computed: {
    ...mapStores(useOrderStore, useMeStore),
    maxTickets() {
      return this.purchasableCount > 0 ? this.purchasableCount : 0
    },
    purchasableCount() {
      return this.reward.redeemable_count > 0
        ? this.reward.redeemable_count - this.reward.purchased_count
        : this.maxSelectableTickets
    },
    cost() {
      return this.reward.price * this.tickets
    },
    pointsAvailable() {
      return this.meStore.points >= this.cost
    },
    allInOnly() {
      if (this.reward.all_in_only) {
        this.allIn()
        return true
      }
      return false
    },
    currencyIcon() {
      return this.meStore.currencyIcon
    },
  },
  watch: {
    maxTickets(newVal) {
      if (newVal === 0) {
        this.tickets = 0
      }
    },
  },
  mounted() {
    if (this.maxTickets > 0) {
      this.tickets = 1
    }
  },
  methods: {
    redeem() {
      const percentageRedeemed = this.cost / this.meStore.points
      if (this.allInPressed && percentageRedeemed >= 0.9) {
        this.$track('raffle/allin')
      }

      this.orderStore.setRaffleTickets(this.tickets)
      this.orderStore.SET_STATE('confirm')
    },
    allIn() {
      const maxBuyable = Math.floor(this.meStore.points / this.reward.price)
      const max =
        this.reward.redeemable_count &&
        this.reward.redeemable_count - this.reward.purchased_count < maxBuyable
          ? this.reward.redeemable_count - this.reward.purchased_count
          : maxBuyable

      if (max > this.maxSelectableTickets) {
        this.maxSelectableTickets = max
      }

      this.allInPressed = true

      setTimeout(() => {
        this.setAllIn(max)
      }, 0)
    },
    setAllIn(value) {
      this.tickets = value
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  @apply text-theme-secondary font-theme-title text-2xl uppercase;
}

.text {
  @apply text-theme-primary font-theme-text leading-tight pb-4 pt-2 pb-2;
}

.hint {
  @apply text-theme-primary font-theme-text leading-tight text-sm;
}

.details {
  @apply flex justify-between;
}

.price {
  @apply flex;

  p {
    @apply text-theme-primary text-2xl pr-2;
    padding-top: 0.2rem;
  }

  img {
    @apply h-8;
  }
}

.info-space {
  @apply mt-4;
  min-height: 2.5rem;
}

.error {
  @apply text-theme-secondary;
}

.button-group {
  @apply flex justify-between mt-4;
}

.allin-button {
  @apply pb-1 pt-2 px-5 font-theme-title tracking-title text-theme-primary rounded-full uppercase shadow-box-lg text-lg;
}
</style>
