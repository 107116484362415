<template>
  <div>
    <input
      v-model="answer"
      :type="question.type"
      :placeholder="`${question.question}*`"
    />
  </div>
</template>

<script>
export default {
  props: {
    question: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      answer: ''
    }
  },

  watch: {
    answer(newVal) {
      this.$emit('updated', {
        name: this.question.name,
        answer: newVal,
        filled: newVal.toString().length > 0
      })
    }
  }
}
</script>

<style lang="scss" scoped>
input[type=string],
input[type=text],
input[type=number] {
  @apply bg-white p-2 my-2 w-full text-theme-primary h-10 text-base border-theme-primary border border-solid;
  box-sizing: border-box;
}
</style>
