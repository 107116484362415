<template>
  <swiper-container
    class="swiper payment-process swiper-no-swiping"
    ref="paymentSwiper"
    slides-per-view="1"
    centered-slides="true"
    allow-touch-mode="false"
    no-swiping="true"
    space-between="35"
    start-index="1"
  >
    <swiper-slide class="reward-redeem-footer">
      <EssentialsDefaultButtonEvent :disabled="true">{{
        $t('components.rewards.redeem')
      }}</EssentialsDefaultButtonEvent>
    </swiper-slide>

    <swiper-slide class="swiper-slide">
      <RewardsOrderPanel class="precheck-box" :loading="loading">
        <RewardsOrderRaffleTicketSelectionBox
          v-if="reward.payment_type === 'raffle'"
          :reward="reward"
          @retryClose="retryClose()"
        />
        <RewardsOrderReservateBox v-else />
      </RewardsOrderPanel>
    </swiper-slide>
    <swiper-slide class="swiper-slide">
      <RewardsOrderPanel class="confirm-box" :loading="loading">
        <div v-if="reward.payment_type === 'raffle'">
          <RewardsOrderConfirmRaffle
            :reward="reward"
            :loading="loading"
            @retryClose="retryClose()"
          />
        </div>
        <div v-else>
          <RewardsOrderConfirmDelivery
            v-if="deliveryType === 'delivery'"
            :reward="reward"
            :loading="loading"
            @retryClose="retryClose()"
          />
          <RewardsOrderConfirm
            v-else
            :reward="reward"
            :loading="loading"
            @retryClose="retryClose()"
          />
        </div>
      </RewardsOrderPanel>
    </swiper-slide>
    <swiper-slide class="swiper-slide">
      <RewardsOrderPanel class="error-box">
        <RewardsOrderErrorBox :reward="reward" @retryClose="retryClose()" />
      </RewardsOrderPanel>
    </swiper-slide>
    <swiper-slide class="swiper-slide">
      <RewardsOrderPanel class="success-box" :loading="loading">
        <RewardsOrderSuccessBox
          :reward="reward"
          @resetClose="resetClose()"
          @resetAndBack="resetAndBack()"
          @resetAndNavigate="resetAndNavigate"
        />
      </RewardsOrderPanel>
    </swiper-slide>
  </swiper-container>
</template>

<script>
import { mapStores } from 'pinia';

export default {
  props: {
    reward: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      swipeNextMatch: {
        confirm: 2,
        error: 3,
        success: 4,
      },
    };
  },
  computed: {
    ...mapStores(useRewardsStore, useOrderStore, useMeStore),
    state() {
      return this.orderStore.state;
    },
    deliveryType() {
      return this.reward.delivery_type;
    },
    loading() {
      return this.state === 'loading';
    },
    mySwiper() {
      return this.$refs.paymentSwiper.swiper;
    },
  },
  watch: {
    state(newState) {
      if (Object.prototype.hasOwnProperty.call(this.swipeNextMatch, newState)) {
        this.mySwiper.slideTo(this.swipeNextMatch[newState]);
      }
      if (newState === 'error' || newState === 'success') {
        this.meStore.fetchCollector();
        this.meStore.fetchPersonalData();
        this.rewardsStore.fetchSingleReward(this.reward.id);
      }
    },
  },
  mounted() {
    this.mySwiper.slideTo(1);

    if (this.reward.payment_type === 'buy') {
      this.orderStore.reservate(this.reward.id);
    } else if (this.reward.payment_type === 'raffle') {
      this.orderStore.SET_STATE('raffleticket');
    }
  },
  methods: {
    retryClose() {
      this.mySwiper.slideTo(0);
      this.orderStore.resetAndReload();
      this.orderStore.releaseReservation(this.reward.id);
      this.$emit('reset');
    },
    resetClose() {
      this.mySwiper.slideTo(0);
      this.orderStore.resetAndReload();
      this.$emit('reset');
    },
    resetAndBack() {
      this.orderStore.resetAndReload();
      this.$emit('reset');
      this.$router.push({ name: 'me-rewards' });
    },
    resetAndNavigate(event) {
      this.orderStore.resetAndReload();
      this.$emit('reset');
      this.$router.push(event);
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-process {
}

.reward-redeem-footer {
  @apply w-full text-center bottom-0 py-2 bg-white;
}

.title {
  @apply text-theme-secondary font-theme-title text-2xl uppercase pb-2;
}

.text {
  @apply text-theme-primary font-theme-text leading-tight;
}

.swiper-container {
  @apply w-full block py-4;
}

.swiper-wrapper {
  @apply pb-4;
}
</style>
