<template>
  <div>
    <label class="cbcontainer">
      <slot />
      <input v-model="checked" type="checkbox" />
      <span class="checkmark" />
    </label>
  </div>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    checked: {
      get() {
        return this.modelValue;
      },
      set(val) {
        // Communicate the change to parent component so that selectedValue can be updated
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
%container-bg {
  @apply bg-white border border-solid border-theme-primary;
  height: 29px !important;
  width: 29px !important;
}

%container-bg-hover {
  @apply bg-theme-primary-light;
}

%container-bg-checked {
  @apply bg-theme-primary;
}

%checkmark-border {
  @apply border-white border-solid;
}

.cbcontainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.cbcontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  @extend %container-bg;
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
}

/* On mouse-over, add a grey background color */
.cbcontainer:hover input ~ .checkmark {
  @extend %container-bg-hover;
}

/* When the checkbox is checked, add a blue background */
.cbcontainer input:checked ~ .checkmark {
  @extend %container-bg-checked;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.cbcontainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.cbcontainer .checkmark:after {
  @extend %checkmark-border;
  left: 8px;
  top: 3px;
  width: 10px;
  height: 15px;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
