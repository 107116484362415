<template>
  <EssentialsDefaultButtonEvent
    v-if="!collector_anonym && !notForYou"
    :disabled="!canRedeem"
    @click="$emit('click')"
  >
    {{ $t(`components.rewards.buttons.${buttonText}`) }}
  </EssentialsDefaultButtonEvent>
  <EssentialsDefaultButtonEvent
    v-else-if="notForYou"
    class="mt-1 button vplus-style"
    @click="openLink"
  >
    {{ $t('components.rewards.buttons.subscribe') }}
  </EssentialsDefaultButtonEvent>
  <RegisterButton v-else />
</template>
<script>
import { mapStores } from 'pinia';

export default {
  props: {
    reward: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapStores(useMeStore, useMainStore),
    canRedeem() {
      return (
        this.available &&
        this.sufficientFunds &&
        !this.collector_anonym &&
        !this.redeemlimitreached &&
        !this.disabled
      );
    },
    available() {
      return this.reward.available;
    },
    redeemlimitreached() {
      if (
        this.reward.redeemable_count > 0 &&
        (this.reward.redeemable_frequency === 'unlimited' ||
          this.reward.redeemable_frequency === '')
      ) {
        return this.reward.purchased_count >= this.reward.redeemable_count;
      }
      return false;
    },
    sufficientFunds() {
      return this.meStore.points >= this.reward.price;
    },
    collector_anonym() {
      return this.meStore.anonymous;
    },
    type_key() {
      if (this.reward.payment_type === 'raffle') {
        return 'participate';
      }

      if (this.reward.payment_type === 'fund') {
        return 'fund';
      }

      return 'redeem';
    },
    buttonText() {
      if (!this.canRedeem) {
        if (!this.sufficientFunds) {
          return this.type_key;
        }
        return 'not_available';
      }

      return this.type_key;
    },
    isPremiumUser() {
      return this.reward.subs_redeemable;
    },
    exclusiveDeal() {
      return this.reward.subs_only;
    },
    notForYou() {
      return this.exclusiveDeal && !this.isPremiumUser;
    },
  },
  methods: {
    openLink() {
      this.mainStore.openExternalLink(
        'https://www.vol.at/abo-vplus?utm_source=Vplus_Rewards&utm_medium=Vplus_Rewards&utm_campaign=vplus_ExclusiveReward&utm_term=vplus_ExclusiveReward_Spezialseite'
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.components-essentials-defaultbuttonevent-container.vplus-style {
  @apply w-4/6 border-white text-white;
  background: #e3051b;
}

.components-essentials-defaultbuttonevent-container.vplus-style:hover {
  @apply bg-white;
  color: #e3051b;
  border-color: #e3051b;
}
</style>
