<template>
  <section class="pages-me-rewards-detail-_id-container">
    <div v-if="reward.id">
      <RewardsGallery :reward="reward" @loaded="galleryLoaded" />
      <div class="reward-container" :style="`margin-top:${contentMarginTop}`">
        <RewardsDetails :reward="reward" />
        <div
          v-show="!showProcess"
          ref="RewardsRedeemButton"
          class="reward-redeem-footer"
        >
          <RewardsRedeemButton
            v-if="!isFullFund"
            :reward="reward"
            :disabled="showProcess"
            @click="redeem()"
          />
        </div>
        <div class="reward-panel">
          <RewardsOrderPaymentFactory
            v-if="showProcess || isFullFund"
            :reward="reward"
            @reset="resetButton()"
          />

          <RewardsInfoBox v-if="state === 'loading'" :reward="reward" />

          <div v-if="reward.payment_type !== 'fund'" class="terms">
            <RewardsAGB />
            <RewardsTermsRaffle v-if="reward.payment_type === 'raffle'" />
            <RewardsTermsReward v-else />
          </div>
          <div id="scrollToPaymentProcess">
            <nuxt-link
              v-if="isSpecialCategory || isSpecialRaffleCategory"
              :to="{ name: 'me-newyear' }"
              class="footer-back-arrow"
            >
              <img src="~/assets/img/backBlack.png" class="back-icon" />
              <p class="pl-2">
                {{ $t('components.newyear.back') }}
              </p>
            </nuxt-link>
            <nuxt-link
              v-else
              :to="{ name: 'me-rewards' }"
              class="footer-back-arrow"
            >
              <img src="~/assets/img/backBlack.png" class="back-icon" />
              <p class="pl-2">
                {{ $t('components.rewards.back') }}
              </p>
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="reward.error">
        <Rewards404 />
      </div>
    </div>
    <div v-if="!loaded" class="fixed top-0 z-30 w-full">
      <img class="w-full" src="~/assets/img/reward_cloak.png" />
    </div>
  </section>
</template>

<script lang="ts">
export default defineNuxtComponent({
  beforeRouteEnter(to, from, next) {
    next((vm: any) => {
      if (from.fullPath !== '/') {
        vm.shouldFetchCollector = true;
      } else {
        vm.shouldFetchCollector = false;
      }
    });
  },

  beforeRouteLeave(to, from, next) {
    this.reset();
    next();
  },

  setup() {
    const rewardsStore = useRewardsStore();
    const orderStore = useOrderStore();
    const meStore = useMeStore();
    const mainStore = useMainStore();
    const route = useRoute();
    const shouldFetchCollector = ref(false);
    const id = computed(() => {
      return Number(route.params.id);
    });

    const { refresh } = useAsyncData(async () => {
      const promises = [];
      if (shouldFetchCollector) {
        promises.push(meStore.fetchCollector());
      }
      promises.push(rewardsStore.fetchSingleReward(id.value));
      promises.push(meStore.fetchPersonalData());

      await Promise.all(promises);
    });

    onActivated(() => {
      resetButton();
      orderStore.reset();
      refresh();
    });

    const reward = computed(() => {
      const reward = rewardsStore.getSingleReward(id.value);
      return reward !== undefined ? reward : {};
    });

    const contentMarginTop = computed(() => {
      return `${imgHeight.value - 40}px`;
    });

    const paymentContainer = computed(() => {
      return process.client
        ? document.getElementById('scrollToPaymentProcess')
        : null;
    });

    const isSpecialCategory = computed(() => {
      return reward.value.categories
        .map((c: any) => c.id)
        .includes(Number(mainStore.special_category));
    });

    const isSpecialRaffleCategory = computed(() => {
      return reward.value.categories
        .map((c: any) => c.id)
        .includes(Number(mainStore.special_raffle_category));
    });

    const isFullFund = computed(() => {
      return reward.value.payment_type === 'fund' && reward.value.fund === 100;
    });

    const notFound = computed(() => {
      return reward.value.error !== undefined;
    });

    const state = computed(() => {
      return orderStore.state;
    });

    const imgHeight = ref(224);
    const loaded = ref(false);
    const showProcess = ref(false);

    watch(notFound, (newVal) => {
      if (newVal) {
        loaded.value = true;
      }
    });

    const galleryLoaded = (e: number) => {
      imgHeight.value = e;
      loaded.value = true;
    };

    const redeem = () => {
      showProcess.value = true;
      if (paymentContainer !== null) {
        paymentContainer.value?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    };

    const resetButton = () => {
      setTimeout(() => {
        showProcess.value = false;
      }, 300);
    };

    return {
      galleryLoaded,
      redeem,
      reset: () => orderStore.reset(),
      resetButton,
      imgHeight,
      loaded,
      showProcess,
      contentMarginTop,
      paymentContainer,
      isSpecialCategory,
      isSpecialRaffleCategory,
      isFullFund,
      notFound,
      state,
      reward,
      shouldFetchCollector,
    };
  },
});
</script>

<style lang="scss" scoped>
.pages-me-rewards-detail-_id-container {
}

.back-arrow {
  @apply fixed top-0 z-10 mt-2 ml-2 z-30;
  img {
    @apply h-6;
  }
}

.reward-container {
  @apply pb-4 relative z-20;
}

.reward-panel {
  @apply bg-white;
  margin-top: -23rem;
  padding-top: 24rem;
}

.reward-redeem-footer {
  @apply w-full text-center bottom-0 sticky py-2 bg-white mt-4;
}

.terms {
  @apply mt-2 h-16 flex justify-between flex-col items-center;
}

#scrollToPaymentProcess {
  @apply mt-4;
}

.footer-back-arrow {
  @apply flex pl-2;

  img {
    @apply h-5;
  }

  p {
    @apply text-theme-primary pl-2 cursor-pointer;
    padding-top: 1px;
  }
}
</style>
